.header {
    position: sticky;
    top: 0;
    z-index: 99;
    height: 72px;
    width: 100%;
    background: #fff;
    border-style: none none solid;
    border-width: 2px;
    border-color: #f1f1f1;
}

.headerContainer {
    margin: 0 auto 0 auto;
    max-width: 1100px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}

.logo {
    height: 40px;
    width: unset;
}

@media only screen and (max-width: 1180px) {
    .headerContainer {
        margin: 0 20px 0 20px;
    }
}


@media only screen and (max-width: 800px) {
}
