.footer {
  position: relative;
  z-index: 99;
  min-height: 10vh;
  width: 100%;
  margin-top: 40px;
  display: flex;
}

.footerContainer {
  margin: 0 auto 0 auto;
  max-width: 1100px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.footerLogo {
  height: 40px;
  width: unset;
}

.linkContainer {
  display: flex;
  flex-direction: row;
  height: 40px;
  align-items: center;
}
.linkContainer a {
  text-decoration: none;
  color: #4b4b4b;
  font-weight: bolder;
  margin-left: 20px;
}

.linkContainer a:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 1180px) {
  .footerContainer {
    margin: 0 20px 0 20px;
  }
}

@media only screen and (max-width: 800px) {

  .footerLogo {
    height: 20px;
    width: unset;
  }

  .linkContainer a {
    font-size: 10px;
  }
}
