.nothing-there-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.nothing-there-container div {
    width: 50%;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    box-sizing: border-box;
    padding-right: 10%;
}


.nothing-there-container div:nth-child(1) {
    background: url("../../assets/noUserFound.png") center no-repeat;
    background-size: auto 70% ;
}

.nothing-there-container h2, .nothing-there-container h4, .nothing-there-container p {
    margin: 0;
    padding: 0;
}

.nothing-there-container h2 {
    font-size: 30px;
    color: #2b2b2b;
}

.nothing-there-container h4 {
    font-size: 20px;
    color: #FD5150;
    font-weight: bold;
}

.nothing-there-container p {
    font-size: 16px;
    color: #4A4A4A;
    line-height: 1.5;
    margin: 15px 0 15px 0;
}

.nothing-there-container a::after {
    content: "";
    height: 30px;
    width: 30px;
    border-radius: 15px;
    background:url("../../assets/logo_04_2020.png") no-repeat center;
    background-size: 100% auto;
    top: 9px;
    left: 9px;
    position: absolute;
    display: inline-block;
}

.nothing-there-container a {
    position: relative;
    padding: 15px 20px 15px 50px;
    background-color: #F7F7F7;
    color: #4B4B4B;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    border-radius: 30px;
}

@media only screen and (max-width: 800px) {

    .nothing-there-container {
        height: 100%;
        width: 100%;
        flex-direction: column;
    }

    .nothing-there-container div {
        width: 100%;
        align-items: center;
        padding: 0 10% 0 10%;
    }

    .nothing-there-container p {
        text-align: center;
    }
}
