.landingContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 60px 20%;
}

.landingContainer textarea,
.landingContainer input[type="text"],
.landingContainer input[type="email"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.accordion__button {
  box-sizing: border-box;
}

.landingContainer a {
  text-align: center;
}

@media only screen and (max-width: 1080px) {
  .upperContainer {
    flex-direction: column !important;
  }

  .upperInnerContainer {
    width: 100% !important;
    box-sizing: border-box;
    margin-bottom: 10px !important;
  }
}

@media only screen and (max-width: 880px) {
  .landingContainer {
    padding: 60px 5%;
  }
}
